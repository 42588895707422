import { ref, computed } from '@vue/composition-api'
import XLSX from 'xlsx'
import store from '@/store'

export default function useMasterCatalogueForm() {
  const blankMasterCatalogueData = {
    operation: 'U',
    products: [],
    subactivity_id: '',
  }

  const masterCatalogueData = ref(JSON.parse(JSON.stringify(blankMasterCatalogueData)))

  const templateData = [
    {
      isWeightable: 'N',
      PLU: null,
      vatSlabId: 1,
      askPriceFlag: 'N',
      price: 15,
      applicableInventoryTransactionFlag: 'Y',
      isAvailableOnPOS: 'Y',
      isAvailableOnEcom: 'Y',
      POSDescription: 'Test Product 1',
    },
    {
      isWeightable: 'N',
      PLU: null,
      vatSlabId: 1,
      askPriceFlag: 'N',
      price: 25,
      applicableInventoryTransactionFlag: 'Y',
      isAvailableOnPOS: 'Y',
      isAvailableOnEcom: 'Y',
      POSDescription: 'Test Product 2',
    },
  ]

  const previewData = ref([])

  store.dispatch('app-activity/fetchActivities')

  const activityOptions = computed(() => store.getters['app-activity/activityOptions'])

  // TODO: remove fetch sub activities from here
  const fetchSubActivities = activityId => store.dispatch('app-activity/fetchActivity', activityId)

  const subActivityOptions = computed(() => store.getters['app-activity/subActivityOptions'])

  const downloadTemplate = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(templateData)
    XLSX.utils.book_append_sheet(wb, ws, 'master-catalogue-template')
    XLSX.writeFile(wb, 'download-master-catalogue-template.csv')
  }

  const CSVtoJson = event => {
    let workBook = null
    let jsonData = []
    const formattedData = []
    const reader = new FileReader()
    const file = event.target.files[0]
    reader.onload = () => {
      const data = reader.result
      workBook = XLSX.read(data, { type: 'binary' })
      jsonData = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]])

      const getObj = obj => {
        Object.keys(obj).forEach(key => { obj[key] = decodeURIComponent(escape(obj[key])) })
        return obj
      }

      for (let index = 0; index < jsonData.length; index++) {
        formattedData.push(getObj(jsonData[index]))
      }

      previewData.value = formattedData
    }
    reader.readAsBinaryString(file)
  }

  return {
    masterCatalogueData,
    previewData,

    fetchSubActivities,

    downloadTemplate,
    CSVtoJson,

    // Options
    activityOptions,
    subActivityOptions,
  }
}
