<template>
  <b-card>

    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(upload)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <master-catalogue-form :master-catalogue-data="masterCatalogueData" />

      </b-form>

    </validation-observer>

  </b-card>
</template>

<script>
import
{
  BCard,
  BForm,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import masterCatalogueStoreModule from '../masterCatalogueStoreModule'
import MasterCatalogueForm from '../master-catalogue-form/MasterCatalogueForm.vue'
import useMasterCatalogueForm from '../master-catalogue-form/useMasterCatalogueForm'
import activityStoreModule from '../../../activities-and-subactivities/activityStoreModule'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,

    MasterCatalogueForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const MASTER_CATALOGUE_APP_STORE_MODULE_NAME = 'app-master-catalogue'

    // Register module
    if (!store.hasModule(MASTER_CATALOGUE_APP_STORE_MODULE_NAME)) store.registerModule(MASTER_CATALOGUE_APP_STORE_MODULE_NAME, masterCatalogueStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MASTER_CATALOGUE_APP_STORE_MODULE_NAME)) store.unregisterModule(MASTER_CATALOGUE_APP_STORE_MODULE_NAME)
    })

    const ACTIVITY_APP_STORE_MODULE_NAME = 'app-activity'

    // Register module
    if (!store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.registerModule(ACTIVITY_APP_STORE_MODULE_NAME, activityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACTIVITY_APP_STORE_MODULE_NAME)
    })

    const { masterCatalogueData } = useMasterCatalogueForm()

    async function upload() {
      try {
        const response = await store.dispatch('app-master-catalogue/uploadMasterCatalogue', masterCatalogueData.value)

        if (response.data.status) {
          router.go()
        }
      } catch (error) {
        console.error(error)
      }
    }

    return {
      masterCatalogueData,

      upload,
    }
  },
}
</script>
