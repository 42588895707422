import { uploadMasterCatalogueAPI } from '@/services/apis'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async uploadMasterCatalogue(ctx, params) {
      try {
        const formData = new FormData()
        formData.append('operation', params.operation)
        formData.append('activityId', params.activityId)
        formData.append('subactivity_id', params.subactivity_id)
        formData.append('products', params.products)
        const response = await this.$axios.post(uploadMasterCatalogueAPI, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent
              const percent = Math.floor((loaded * 100) / total)

              if (percent < 100) {
                ctx.commit('appLoaderConfig/SET_UPLOAD_PERCENT', percent, { root: true })
              }
            },
          })

        ctx.commit('appLoaderConfig/SET_UPLOAD_PERCENT', 100, { root: true })
        return response
      } catch (error) {
        return error
      }
    },
  },
}
