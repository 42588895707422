<template>
  <b-row>

    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Activity"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Activity"
          label-for="activity"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="masterCatalogueData.activityId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="activityOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="activity"
            @input="fetchSubActivities(masterCatalogueData.activityId)"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Sub Activity"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Sub Activity"
          label-for="sub-activity"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="masterCatalogueData.subactivity_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="subActivityOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="sub-activity"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col sm="6">
      <label>Download Template</label>
      <div class="dotted-box text-center p-4">
        <b-button
          variant="primary"
          class="text-center"
          @click="downloadTemplate"
        >
          <span class="text-nowrap">Download Template</span>
        </b-button>
      </div>
    </b-col>

    <b-col sm="6">
      <label>Upload Products</label>
      <div class="dotted-box p-4">
        <b-input-group>
          <b-input-group-prepend v-if="masterCatalogueData.products && masterCatalogueData.products.name">
            <b-button
              variant="outline-danger"
              size="sm"
              @click="masterCatalogueData.products = [], previewData = []"
            >
              Reset
            </b-button>
          </b-input-group-prepend>
          <b-form-file
            v-model="masterCatalogueData.products"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            accept=".csv"
            no-drop
            @change="CSVtoJson($event)"
          />
        </b-input-group>
      </div>
    </b-col>

    <b-col v-if="previewData.length > 0">
      <label class="mt-2">Preview CSV</label>
      <b-table
        ref="refPreviewListTable"
        class="position-relative"
        :items="previewData"
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      />

    </b-col>

  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider } from 'vee-validate'
import useMasterCatalogueForm from './useMasterCatalogueForm'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    vSelect,

    ValidationProvider,
  },
  props: {
    masterCatalogueData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getValidationState } = formValidation()

    const {
      fetchSubActivities,
      previewData,

      // Actions
      downloadTemplate,
      CSVtoJson,

      // Options
      activityOptions,
      subActivityOptions,
    } = useMasterCatalogueForm()

    return {
      getValidationState,

      fetchSubActivities,
      previewData,

      //   Actions
      downloadTemplate,
      CSVtoJson,

      //  Options
      activityOptions,
      subActivityOptions,
    }
  },
}
</script>

<style scoped>
  .dotted-box {
      border: 1px dashed rgba(82, 67, 170, 0.4);
  }
</style>
